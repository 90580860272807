<template>
  <Chat
    :events="approval.events"
    :newMessageDisabled="newMessageDisabled"
    :atSendMessage="sendMessage"
    :maxAttachmentSize="maxAttachmentSize"
    :isExternalChat="isExternalChat"
    :fullHeight="fullHeight"
    :highlight="highlight"
    :atMarkMessagesAsViewed="(ids) => markApprovalMessagesAsViewed(ids)"
    :viewedAtEnabled="!isExternalChat"
    @messages-sent="(data) => $emit('messages-sent', data)"
  />
</template>

<script>
import APIService from '@/services/api';

import { MAX_DOCUMENT_SIZE_MB } from '@/settings';

import { SET_APPROVAL_MESSAGES_READED_NS } from '@/store/approvals';

import Chat from '@/components/Chat.vue';

const MAX_APPROVAL_DOCUMENT_SIZE_MB = MAX_DOCUMENT_SIZE_MB.approval;

export default {
  name: 'ApprovalChat',
  components: { Chat },
  props: {
    approval: {
      type: Object,
      required: true,
    },
    newMessageDisabled: {
      type: Boolean,
      default: false,
    },
    isExternalChat: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return { maxAttachmentSize: MAX_APPROVAL_DOCUMENT_SIZE_MB };
  },
  computed: {
    isUserHijacked() {
      return this.$store.state.authentication.hijackedUserId !== null;
    },
    isUserAuthority() {
      return this.$store.getters['authentication/isUserAuthority'];
    },
    isAuthorityAdminOrManager() {
      return this.$store.getters['authentication/isAuthorityAdminOrManager'];
    },
  },
  methods: {
    buildPayload(message, attachedFiles, myDocuments) {
      const payload = new FormData();
      payload.append('message', message);
      attachedFiles.forEach(
        (file, i) => payload.append(`document_${i + 1}`, file),
      );
      if (myDocuments.length) {
        payload.append('my_documents', JSON.stringify(myDocuments.map((d) => d.documentId)));
      }
      return payload;
    },
    async sendMessage(message, attachedFiles, myDocuments) {
      const payload = this.buildPayload(message, attachedFiles, myDocuments);
      return APIService.approvalMessage(this.approval.id, payload).then(({ data }) => data);
    },
    markApprovalMessagesAsViewed(ids) {
      if (this.isUserHijacked || (this.isUserAuthority && !this.isAuthorityAdminOrManager)) return;
      APIService.markApprovalMessagesAsViewed(this.approval.id, ids)
        .then(() => {
          this.$store.commit(SET_APPROVAL_MESSAGES_READED_NS, { approvalId: this.approval.id });
        });
    },
  },
};
</script>
