<template>
  <LayoutListingMap :display-button-add="canCreateInstance">
    <template
      slot="listing"
      slot-scope="{display, toggleBiggerDisplay, status}"
    >
      <v-tabs
        v-model="activeTab"
        v-if="isStatusRead(status)"
        color="white"
        hide-slider
        background-color="secondary"
        dark
        grow
        show-arrows
        center-active
        class="default--tabs"
      >
        <!-- ----------- Approvals tab ------- -->
        <template v-if="approvalAccessPrivilege">
          <v-tab
            :href="`#${tabs.approvals}`"
            ripple
            class="subtitle-2"
            @click="changeTab(tabs.approvals)"
          >
            <span>
              <translate>Approvals</translate>
              ({{ approvalsLength }})
            </span>
          </v-tab>
          <v-tab-item
            :value="tabs.approvals"
            class="tab-item"
            eager
          >
            <ApprovalFilters
              :display="display"
              :is-user-authority="isUserAuthority"
              @toggle-filter="toggleBiggerDisplay"
            />
            <ApprovalListing
              :display="display"
              @center-on-approval="() => centerOnApproval()"
              @center-on-area="(area) => centerOnArea(area)"
            />
          </v-tab-item>
        </template>
        <!-- ----------- Activations tab ------- -->
        <template v-if="activationAccessPrivilege">
          <v-tab
            :href="`#${tabs.activations}`"
            ripple
            class="subtitle-2"
            @click="changeTab(tabs.activations)"
          >
            <span>
              <translate>Activations</translate>
              <span>
                ({{ activationsLength }})
              </span>
            </span>
          </v-tab>
          <v-tab-item
            :value="tabs.activations"
            class="tab-item"
            eager
          >
            <ActivationFilters
              :display="display"
              :is-user-authority="isUserAuthority"
              @toggle-filter="toggleBiggerDisplay"
            />
            <ActivationListing @center-on-area="(area) => centerOnArea(area)" />
          </v-tab-item>
        </template>
        <!-- ----------- Custom Layers tab ------- -->
        <v-tab
          :href="`#${tabs.layers}`"
          ripple
          class="subtitle-2"
          @click="changeTab(tabs.layers)"
        >
          <span>
            <translate>Zones</translate>
            ({{ customLayersLength }})
          </span>
        </v-tab>
        <v-tab-item
          :value="tabs.layers"
          class="tab-item"
        >
          <CustomLayersListing
            v-if="isStatusRead(status)"
            @edit-layer="(layer) => editLayer(layer)"
          />
        </v-tab-item>
        <!-- Tracker traces tab -->
        <template v-if="trackingActivated">
          <v-tab
            :href="`#${tabs.traces}`"
            ripple
            class="subtitle-2"
            @click="changeTab(tabs.traces)"
          >
            <span>
              <translate>Traces</translate>
              <span>
                ({{ tracesLength }})
              </span>
            </span>
          </v-tab>
          <v-tab-item
            :value="tabs.traces"
            class="tab-item"
          >
            <TrackerTraceFilters
              :display="display"
              @toggle-filter="toggleBiggerDisplay"
            />
            <TrackerTracesListing @center-on-area="(area) => centerOnArea(area)" />
          </v-tab-item>
        </template>
      </v-tabs>
      <!-- ------- Custom Layer Form -------- -->
      <CustomLayerForm
        v-if="!isStatusRead(status) && activeTab === tabs.layers"
        :customLayerToEdit="customLayerToEdit"
        @reset-custom-layer-to-edit="resetCustomLayerToEdit()"
      />
    </template>
    <MapboxComponent
      slot="map"
      slot-scope="{display}"
      :display="display"
    />
  </LayoutListingMap>
</template>

<script>
import {
  APPLICATION_STATUS,
  APPLICATION_TAB,
  SET_ACTIVE_TAB_NS,
  SET_STATUS_NS,
} from '@/store/application';
import { REFRESH_USER_DATA_NS } from '@/store/authentication';
import { SET_ACTIVATION_SELECTED_NS } from '@/store/activations';
import { SET_APPROVAL_SELECTED_NS } from '@/store/approvals';
import {
  DISABLE_CUSTOM_LAYER_NS,
  HIDE_CUSTOM_LAYER_NS,
  MAP_STATUS,
  SET_MAP_AREA_NS,
  SET_MAP_STATUS_NS,
  SHOW_TRACES_NS,
  ZOOM_TO_AREA_NS,
} from '@/store/map';
import {
  GET_AUTHORITY_LAYERS_NS,
  GET_AUTHORITY_STRUCTURES_NS,
  GET_STRUCTURES_AIP_GEOMETRIES_NS,
} from '@/store/structures';

import LayoutListingMap from '@/layouts/LayoutListingMap.vue';
import ActivationListing from '@/components/Activations/ActivationListing.vue';
import ActivationFilters from '@/components/Activations/ActivationFilters.vue';
import ApprovalFilters from '@/components/Approvals/ApprovalFilters.vue';
import ApprovalListing from '@/components/Approvals/ApprovalListing.vue';
import CustomLayerForm from '@/components/CustomLayers/CustomLayerForm.vue';
import CustomLayersListing from '@/components/CustomLayers/CustomLayersListing.vue';
import Map from '@/components/Map/Map.vue';
import TrackerTraceFilters from '@/components/TrackerTraces/TrackerTraceFilters.vue';
import TrackerTracesListing from '@/components/TrackerTraces/TrackerTracesListing.vue';

export default {
  name: 'MapAuthorities',
  components: {
    LayoutListingMap,
    ActivationListing,
    ApprovalFilters,
    ActivationFilters,
    ApprovalListing,
    CustomLayerForm,
    CustomLayersListing,
    MapboxComponent: Map,
    TrackerTraceFilters,
    TrackerTracesListing,
  },
  data() {
    return {
      tabs: {
        approvals: 'approvals',
        activations: 'activations',
        layers: 'layers',
        traces: 'traces',
      },
      activeTab: '',
      customLayerToEdit: null,
    };
  },
  computed: {
    mapCenters() {
      return this.$store.state.approvals.mapCenters;
    },
    mapAreas() {
      return this.$store.state.approvals.mapAreas;
    },
    isUserAuthority() {
      return this.$store.getters['authentication/isUserAuthority'];
    },
    isAuthorityAdmin() {
      return this.$store.getters['authentication/isAuthorityAdmin'];
    },
    approvalAccessPrivilege() {
      return this.$store.getters['authentication/authorityHasApprovalsPrivileges'];
    },
    activationAccessPrivilege() {
      return this.$store.getters['authentication/authorityHasActivationsPrivileges'];
    },
    trackingActivated() {
      return this.$store.getters['authentication/trackingActivated'];
    },
    isAuthorityFullScreenInterface() {
      return this.$store.getters['authentication/isAuthorityFullScreenInterface'];
    },
    approvalsLength() {
      return this.$store.state.approvals.itemsLength;
    },
    activationsLength() {
      return this.$store.state.activations.itemsLength;
    },
    customLayersLength() {
      return this.$store.state.structures.layers.length;
    },
    tracesLength() {
      return this.$store.state.traces.itemsLength;
    },
    idSelected() {
      return this.$store.state.map.featureIdSelected.flight;
    },
    canCreateInstance() {
      if (this.activeTab === this.tabs.layers) {
        return this.isAuthorityAdmin;
      }
      return false;
    },
  },
  watch: {
    // eslint-disable-next-line
    '$route.name'(newValue) {
      this.changeTabFromRoute(newValue);
    },
    idSelected(newValue) {
      if (!newValue) return;
      // change tab to approvals or activations if idSelected and neither is active
      if (![this.tabs.approvals, this.tabs.activations].includes(this.activeTab)) {
        if (this.approvalAccessPrivilege) this.changeTab(this.tabs.approvals);
        else if (this.activationAccessPrivilege) this.changeTab(this.tabs.activations);
      }
      if (this.isAuthorityFullScreenInterface) {
        if (this.approvalAccessPrivilege && this.activeTab === this.tabs.approvals) {
          this.$router.push({ name: 'approval-page', params: { id: newValue } });
        }
        if (this.activationAccessPrivilege && this.activeTab === this.tabs.activations) {
          this.$router.push({ name: 'activation-page', params: { id: newValue } });
        }
      }
    },
    trackingActivated(newValue) {
      if (newValue) {
        this.changeTabFromRoute(this.$route.name);
      }
    },
  },
  async created() {
    await this.$store.dispatch(REFRESH_USER_DATA_NS);
    this.changeTabFromRoute(this.$route.name);
    // fetch authority data
    this.getAuthorityLayers();
    await this.getAuthorityStructures();
    this.$store.dispatch(GET_STRUCTURES_AIP_GEOMETRIES_NS);
  },
  destroyed() {
    if (this.$store.getters['approvals/approvalSelected']) {
      this.$store.dispatch(SET_APPROVAL_SELECTED_NS, null);
    }
    if (this.$store.getters['activations/activationSelected']) {
      this.$store.dispatch(SET_ACTIVATION_SELECTED_NS, null);
    }
  },
  methods: {
    isStatusRead(status) {
      return status === APPLICATION_STATUS.READ;
    },
    centerOnApproval() {
      this.$store.dispatch(ZOOM_TO_AREA_NS, this.$store.getters['approvals/approvalArea']);
    },
    centerOnArea(area) {
      this.$store.dispatch(ZOOM_TO_AREA_NS, area);
    },
    async getAuthorityLayers() {
      await this.$store.dispatch(GET_AUTHORITY_LAYERS_NS);
    },
    changeTabFromRoute(routeName) {
      const tabs = [this.tabs.approvals, this.tabs.activations, this.tabs.layers, this.tabs.traces];
      if (tabs.includes(routeName)) {
        this.changeTab(routeName);
      } else if (this.approvalAccessPrivilege) this.changeTab(this.tabs.approvals);
      else if (this.activationAccessPrivilege) this.changeTab(this.tabs.activations);
      else this.changeTab(this.tabs.layers);
    },
    changeTab(name) {
      switch (name) {
        case 'approvals':
          this.activeTab = this.tabs.approvals;
          this.$store.dispatch(SET_ACTIVE_TAB_NS, APPLICATION_TAB.APPROVAL);
          if (this.$route.name !== 'approvals') {
            this.$router.push('/map/approvals');
          }
          break;
        case 'activations':
          this.activeTab = this.tabs.activations;
          this.$store.dispatch(SET_ACTIVE_TAB_NS, APPLICATION_TAB.ACTIVATION);
          if (this.$route.name !== 'activations') {
            this.$router.push('/map/activations');
          }
          break;
        case 'layers':
          this.activeTab = this.tabs.layers;
          this.$store.dispatch(SET_ACTIVE_TAB_NS, APPLICATION_TAB.LAYER);
          if (this.$route.name !== 'layers') {
            this.$router.push('/map/layers');
          }
          break;
        case 'traces':
          this.activeTab = this.tabs.traces;
          this.$store.dispatch(SET_ACTIVE_TAB_NS, APPLICATION_TAB.TRACE);
          if (this.$route.name !== 'traces') {
            this.$router.push('/map/traces');
          }
          this.$store.dispatch(SHOW_TRACES_NS);
          break;
        default:
      }
    },
    editLayer(layer) {
      this.customLayerToEdit = layer;
      this.$store.dispatch(SET_MAP_AREA_NS, this.customLayerToFeatureCollection(layer));
      this.$store.dispatch(HIDE_CUSTOM_LAYER_NS, { layerId: layer.id });
      this.$store.dispatch(DISABLE_CUSTOM_LAYER_NS, { layerId: layer.id });
      this.$store.dispatch(SET_MAP_STATUS_NS, MAP_STATUS.WRITE);
      this.$store.dispatch(SET_STATUS_NS, APPLICATION_STATUS.UPDATE);
    },
    resetCustomLayerToEdit() {
      this.customLayerToEdit = null;
      this.$store.dispatch(SET_MAP_AREA_NS, null);
    },
    async getAuthorityStructures() {
      await this.$store.dispatch(GET_AUTHORITY_STRUCTURES_NS);
    },
    customLayerToFeatureCollection(layer) {
      return {
        type: 'FeatureCollection',
        features: layer.areas.map((a) => {
          const feature = {
            type: 'Feature',
            geometry: a.geometry,
          };
          return feature;
        }),
      };
    },
  },
};

</script>
